import React from "react";
import styled from "styled-components";
import Paragraph from '../../../atoms/Paragraph';
import Responsive from "react-responsive";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 70px 20px;

  @media only screen and (min-width: 992px) {
    padding: 100px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 38px;
    line-height: 3rem;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: white;
  font-size: 18px;
  line-height: normal;

  @media only screen and (min-width: 992px) {
    max-width: 450px;
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  max-width: 198px;
  height: 60px;
  padding: 0 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
    
  @media only screen and (min-width: 992px) {
    padding-right: 150px;
    width: 50%;
  }
`;

const ImageBg = styled.div`
  width: 100%;
  background-image: url(${props => props.image});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
`;

const Image = styled.img`
    @media only screen and (min-width: 992px) {
        width: 380px;
        height: auto;
        display: block;
    }

    @media only screen and (min-width: 1800px) {
        width: 456px;
        height: auto;
    }
`;

const RightSide = styled.div`
  width: 100%;
  background-color: #c2b8ab;
  
  @media only screen and (min-width: 992px) {
      width: 50%;
  }
`;

const Mobile = props => <Responsive {...props} maxWidth={991} />;
const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
const Desktop = props => <Responsive {...props} minWidth={992} />;

const ImageAndTextAlternative = ({ onClick, title, image, children }) => (
  <>
    <Wrapper>
      <LeftSide>
        <Mobile>
          <ImageBg image={image} />
        </Mobile>
        <Desktop>
          <Image src={image} />
        </Desktop>
      </LeftSide>
      <RightSide>
        <TextWrapper>
          <Title>{title}</Title>
          <StyledParagraph>{children}</StyledParagraph>
          <StyledButton onClick={onClick}>Neem contact op</StyledButton>
        </TextWrapper>
      </RightSide>
    </Wrapper>
  </>
);

export default ImageAndTextAlternative;
