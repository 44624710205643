import React from "react";
import styled from "styled-components";
import Paragraph from '../../../atoms/Paragraph';
import Container from '../../../atoms/Container';
import Link from './Link';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 70px 0;

  @media only screen and (min-width: 992px) {
    padding: 100px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 0;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 38px;
    line-height: 3rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 20px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  color: white;
  
  @media only screen and (min-width: 768px) {
    display: block;
    font-size: 22px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: white;
  font-size: 18px;
  line-height: normal;
  
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  span {
    font-weight: 700;
    display: block;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #7f7c6d;
  
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const StyledContainer = styled(Container)`
    max-width: 1000px;
`;

const StyledLink = styled(Link)`
  border: 0;
  outline: 0;
  width: fit-content;
  height: 60px;
  padding: 19px 35px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #c2b8ab;
  color: white;
  text-decoration: none;

  &:hover {
    background-color: #c2b8ab;
  }
`;

const TextCentered = ({ title, subTitle, children, onClick, buttonText }) => (
  <>
    <Wrapper>
      <StyledContainer>
        <TextWrapper>
          <Title>{title}</Title>
          <Subtitle>{subTitle}</Subtitle>
          <br />
          <StyledParagraph>
            {children}
          </StyledParagraph>
          <StyledLink href={onClick}>{buttonText}</StyledLink>
        </TextWrapper>
      </StyledContainer>
    </Wrapper>
  </>
);

export default TextCentered;
