import React from "react";
import styled from "styled-components";
import { color } from "../../templates/ui";
import ImageAndText from './components/ImageAndText';
import TextCentered from './components/TextCentered';
import ImageAndTextAlternative from './components/ImageAndTextAlternative';
import Link from './components/Link';
import SubTitle from './components/SubTitle';

const StyledInteriorAdviceContent = styled.div`
  background-color: ${color.body.base};
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const InteriorAdviceContent = ({ designScrollRef, onClick }) => (
  <StyledInteriorAdviceContent ref={designScrollRef}>
    <ImageAndText title="Interieuradvies Putten" buttonText="Kan ik je helpen?" onClick={onClick} image="/img/landingpage/interieuradvies-putten.jpg">
      Woon je in Putten en ben je op zoek naar interieuradvies dat naadloos aansluit bij jouw persoonlijke smaak en
      wensen? Bij Livaun sta jij centraal. Of je nu een voorkeur hebt voor een landelijke, moderne, Scandinavische of
      vintage inrichting, kleurrijk of juist neutraal, ik zorg ervoor dat jouw interieur perfect aansluit bij jouw
      unieke stijl en leefomgeving.
      <br/><br/>
      <SubTitle>Uniek en persoonlijk interieuradvies</SubTitle>
      Bij Livaun draait het om het creëren van een interieur dat niet alleen mooi maar ook praktisch is en écht bij jou
      past. Mijn naam is Marjella, het gezicht achter Livaun. Sinds 2018 werk ik als interieurstylist in Putten en
      omgeving. Ik ben afgestudeerd op Academie Artemis in Amsterdam (Bachelor of Arts tot allround creative concept
      developer in trend forecasting, styling en art direction).
      <br/><br/>
      Met mijn passie voor kleur, textuur en materialen, richt ik me op ontwerpen die zowel stijlvol als functioneel
      zijn. Ik geloof dat een nieuw interieur niet altijd betekent dat alles nieuw moet worden gekocht. Door bewust te
      kijken naar wat hergebruikt kan worden, kies ik binnen interieuradvies voor een duurzame aanpak die niet alleen
      goed is voor het milieu, maar ook zorgt voor een persoonlijke en unieke touch in jouw woning.
    </ImageAndText>
    <TextCentered title="Interieuradvies aan huis in Putten" onClick="/" buttonText="Bekijk de mogelijkheden">
      Wil je graag <Link href="/" target="_blank">persoonlijk interieuradvies</Link> bij jou thuis in Putten? Ik kom
      graag bij je langs om je te helpen met al je woonvragen en -wensen. Denk aan vragen over de indeling, kleuren,
      verlichting, meubels of een nieuwe keuken. Of je nu meer gezelligheid en warmte wilt toevoegen aan je huis of je
      huidige interieur een frisse uitstraling wilt geven, ik sta klaar om je te inspireren en te adviseren.<br/><br/>
      Tijdens een adviesgesprek aan huis bespreken we jouw specifieke wensen en geef ik direct praktische tips en
      suggesties die je kunt toepassen. Dit interieuradvies is ideaal voor de doe-het-zelver die net dat extra zetje
      nodig heeft om zijn of haar interieur naar een hoger niveau te tillen. Mijn doel is om jou te inspireren, zodat je
      met zelfvertrouwen aan de slag kunt gaan met je eigen interieur.<br/><br/>

      <SubTitle>Interieuradvies tijdens (ver)bouwprojecten in Putten</SubTitle>
      Ga je binnenkort bouwen of verbouwen in Putten? Dit is een uitstekende gelegenheid om na te denken over je
      interieur. Ik bied interieuradvies aan voor zowel kleine renovaties als grootschalige (ver)bouwprojecten,
      beginnend vanaf één etage. Door al in een vroeg stadium betrokken te raken, kunnen we samen nadenken over de
      ideale indeling, de juiste verlichting, de beste materiaalkeuzes en natuurlijk de uiteindelijke inrichting.
      <br/><br/>
      Door zorgvuldig te plannen en te ontwerpen, zorg ik ervoor dat jouw interieurwensen volledig geïntegreerd worden
      in het bouwproces. Zo creëren we met interieuradvies samen een woning die perfect past bij jouw leefstijl en
      behoeften, met aandacht voor zowel functionaliteit als esthetiek. Zo denk je achteraf nooit; had ik maar…
    </TextCentered>
    <ImageAndTextAlternative title="Waarom kiezen voor een interieurstylist?" onClick={onClick} image="/img/landingpage/interieurstylist-putten.jpg">
      Een interieurstylist kan een wereld van verschil maken in hoe je je woning ervaart. Bij Livaun draait het om het
      creëren van een harmonieus en sfeervol interieur dat echt bij jou past. Ik neem de tijd om jouw persoonlijkheid,
      wensen en dagelijkse routine te leren kennen, zodat de inrichting naadloos aansluit bij wie jij bent en hoe jij
      leeft.
      <br/><br/>
      Met mijn expertise in kleurgebruik, materialen en sfeer help ik je om een interieur te realiseren dat zowel
      functioneel als stijlvol is. Een moodboard of shoppingpagina helpt bij het visualiseren van jouw droominterieur en
      is de basis voor het concreet maken van de plannen. Het moodboard fungeert als leidraad voor de gewenste sfeer,
      kleur en materialen, terwijl de shoppinglijst specifieke productaanbevelingen bevat die je eenvoudig kunt
      aanschaffen.
      <br/><br/>
      <SubTitle>Op maat gemaakt interieurontwerp</SubTitle>
      Bij Livaun bied ik ook uitgebreide interieurontwerpen op maat aan, perfect voor wanneer je je gehele woning een
      nieuwe uitstraling wilt geven of bezig bent met (ver)bouwplannen. Een interieurontwerp geeft een helder en
      gedetailleerd beeld van de totale transformatie, vooral wanneer het om ingrijpende veranderingen gaat.
      <br/><br/>
      Dit omvat onder andere 2D plattegronden voor de indeling en verlichting, en een zorgvuldige selectie van
      materialen en kleuren die de gewenste sfeer en stijl weerspiegelen. Samen werken we een plan uit dat past bij jouw
      wensen, gezinssamenstelling en levensstijl. Of je nu net een nieuw huis in Putten hebt gekocht of je bestaande
      woning wilt vernieuwen.
      <br/><br/>
      <SubTitle>Creëer jouw droominterieur in Putten</SubTitle>
      Ik sta klaar om jou met interieuradvies te helpen bij het realiseren van je droominterieur in Putten. Of je nu
      advies nodig hebt voor een specifieke ruimte, een complete verbouwing plant, of gewoon op zoek bent naar wat
      frisse inspiratie, ik ben er om je te begeleiden.
      <br/><br/>
      Neem contact op om de mogelijkheden voor interieuradvies te bespreken en jouw wensen en ideeën te verkennen. Samen
      zorgen we voor een interieur dat als thuis voelt.
    </ImageAndTextAlternative>
  </StyledInteriorAdviceContent>
);

export default InteriorAdviceContent;
