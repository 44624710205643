import React from "react";
import styled from "styled-components";
import Paragraph from "../atoms/Paragraph";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 70px 20px;

  @media only screen and (min-width: 992px) {
    padding: 100px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: block;
  font-size: 38px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 0;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3rem;
  }
`;

const Subtitle = styled.h3`
  font-size: 20px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  color: white;
  
  @media only screen and (min-width: 768px) {
    display: block;
    font-size: 22px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: white;
  font-size: 18px;
  line-height: normal;
  
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  span {
    font-weight: 700;
    display: block;
  }
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  max-width: 198px;
  height: 60px;
  padding: 0 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  background-color: #c2b8ab;

  @media only screen and (min-width: 992px) {
    width: 50%;
  }
`;

const RightSide = styled.div`
  width: 100%;
  background-color: #7f7c6d;
  
  @media only screen and (min-width: 992px) {
      width: 50%;
  }
`;

const List = styled.ul`
  padding-left: 15px;
  margin-top: 5px;
`;

const Pricing = ({ onClick, pricingScrollRef }) => (
  <>
    <Wrapper ref={pricingScrollRef}>
      <LeftSide>
        <TextWrapper>
          <Title>Advies aan huis</Title>
          <Subtitle>
            Twee uur mondeling advies | €239 excl. btw
          </Subtitle>
          <br />
          <StyledParagraph>
            Een advies aan huis is geschikt voor iedereen die een duwtje in de
            juiste richting nodig heeft. Ik kom twee uur bij jou aan huis om met
            je mee te denken en antwoord te geven op al jouw interieurvragen.
            <br />
            <br />
            Je vragen kunnen bijvoorbeeld gaan over;
            <List>
              <li>Nieuwe kleuren / behang</li>
              <li>Een nieuwe vloer / trapbekleding</li>
              <li>De juiste raamdecoratie</li>
              <li>Je gaat uitbouwen en wilt de indeling opnieuw bekijken</li>
              <li>Je bent toe aan een aantal nieuwe meubels en wilt daarover advies</li>
              <li>Meer eenheid of sfeer creëren</li>
              <li>Optimaliseren en / of aanvullen van het huidige lichtplan</li>
            </List>
            Ik neem verschillende stalen mee om samen te kijken wat goed past.
            Als jouw vraag gaat over de indeling of verlichting, kunnen we (waar
            mogelijk) direct schuiven met wat meubels. Zoek je een geschikte
            nieuwe bank of tafel? Tijdens het gesprek zoek ik passende opties
            die je naderhand zelf kunt gaan bekijken in de winkel.
            <br />
            <br />
            Na het advies aan huis heb kun je zelf direct aan de slag. Heb je
            toch nog vragen of hulp nodig met het vervolg, dat kan op twee
            manieren; namelijk een tweede advies aan huis op een later moment of
            door middel van één of meerdere uitwerkingen in de vorm van een
            moodboard, productpagina, 2D indelingsplan of lichtplan.
          </StyledParagraph>
          <StyledButton onClick={onClick}>Neem contact op</StyledButton>
        </TextWrapper>
      </LeftSide>
      <RightSide>
        <TextWrapper>
          <Title>Uitwerkingen</Title>
          <Subtitle>
            Digitale uitwerkingen voor jouw interieur
          </Subtitle>
          <br />
          <StyledParagraph>
            Vind je het fijn om na het advies aan huis nog één of meerdere
            onderdelen te laten uitwerken? Dat kan! Onderstaande vanaf prijzen
            zijn gebaseerd op een gemiddelde woonkamer met keuken.
            <br />
            <br />
            <span>Moodboard</span>
            Een collage vol ideeën voor bijvoorbeeld jouw woon- of badkamer. Van
            kleuren, materialen en accessoires tot verlichting. Het moodboard
            visualiseert de stijl en sfeer voor jouw ruimte. We bespreken vooraf
            je wensen en ideeën, die ik vertaal naar een mooi geheel inclusief
            kleurpallet.
            <br />
            <br />
            <span>Productpagina</span>
            Een verzameling van items die jij zoekt voor een bepaalde ruimte.
            Door op een product te klikken in dit digitale bestand kom je
            terecht bij het verkooppunt. Je kunt vervolgens langs in de winkel.
            Online bestellen kan natuurlijk ook.
            <br />
            <br />
            <span>2D indelingsplan</span>
            Een nieuw plan voor een ruimte naar wens. Op basis van huidige
            meubels, nieuwe meubels of een mix.
            <br />
            <br />
            <span>Lichtplan</span>
            Een lichtplan voor jouw huidige of nieuwe indeling. Met suggesties
            voor passende verlichting. Eigen verlichting die behouden blijft
            neem ik uiteraard mee ik het plan.
            <br />
            <br />
            De uitwerkingen ontvang je altijd per mail. Je kunt vervolgens zelf
            aan de slag! Vind je het toch fijn om de plannen samen door te nemen
            of jullie input, bijvoorbeeld uitgekozen stalen of meubels n.a.v. de
            uitwerkingen, te bespreken? Dat kan in een tweede advies aan huis.
          </StyledParagraph>
        </TextWrapper>
      </RightSide>
    </Wrapper>
  </>
);

export default Pricing;
