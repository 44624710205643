import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "./pages/Index";
import InteriorAdviceApeldoorn from "./pages/InteriorAdviceApeldoorn";
import Overview from "./pages/Overview";
import InteriorAdvicePutten from "./pages/InteriorAdvicePutten";
import InteriorAdviceHarderwijk from "./pages/InteriorAdviceHarderwijk";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route
          exact
          path="/interieuradvies-apeldoorn"
          component={InteriorAdviceApeldoorn}
        />
        <Route
          exact
          path="/interieuradvies-harderwijk"
          component={InteriorAdviceHarderwijk}
        />
        <Route
          exact
          path="/interieuradvies-putten"
          component={InteriorAdvicePutten}
        />
        <Route exact path="/interieuradvies-livaun" component={Overview} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
