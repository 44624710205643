import React from "react";
import styled from 'styled-components';

const StyledSubTitle = styled.h2`
    font-family: "MyanmarMN Webfont", sans-serif;
    line-height: 1.9rem;
    font-size: 24px;
    font-weight: 700;
    color: inherit;
    margin-bottom: 10px;
    margin-top: 15px;
    text-transform: uppercase;
`;

const SubTitle = ({children}) => (
    <StyledSubTitle>{children}</StyledSubTitle>
);

export default SubTitle;
