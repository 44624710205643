import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  transition: all 0.1s ease-in-out;
  color: inherit;
    
  &:hover {
      text-decoration: none;
  }
`;

const RouterLink = ({ href, children, className, active }) => (
  <StyledLink to={href} className={className} active={active}>
    {children}
  </StyledLink>
);

export default RouterLink;
