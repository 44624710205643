import React from "react";
import styled from "styled-components";
import ImageAndText from './components/ImageAndText';
import TextCentered from './components/TextCentered';
import ImageAndTextAlternative from './components/ImageAndTextAlternative';
import SubTitle from './components/SubTitle';
import Link from './components/Link';

const StyledInteriorAdviceContent = styled.div``;
const InteriorAdviceContent = ({ designScrollRef, onClick }) => (
  <StyledInteriorAdviceContent ref={designScrollRef}>
    <ImageAndText title="Interieuradvies Apeldoorn" buttonText="Kan ik je helpen?" onClick={onClick}
                  image="/img/landingpage/interieuradvies-apeldoorn.jpg">
      Ben je woonachtig in Apeldoorn en op zoek naar interieuradvies dat perfect aansluit bij jouw persoonlijke smaak en
      stijl? Bij Livaun bied ik interieuradvies op maat, waarbij jouw wensen en en stijl centraal staan. Of je nu een
      landelijke, moderne, Scandinavische of vintage inrichting hebt, met veel of juist weinig kleur, ik zorg ervoor dat
      je interieur helemaal wordt afgestemd op jouw unieke voorkeuren en leefwijze.
      <br/><br/>

      <SubTitle>Persoonlijk en duurzaam interieuradvies</SubTitle>
      Bij Livaun draait het om meer dan alleen een mooi interieur. Ik kijk verder dan trends en richt me op een tijdloze
      inrichting die bij jou past. Als interieurstylist houd ik, Marjella, me bezig met de toekomst en nieuwe
      ontwikkelingen. Bewust omgaan met materialen staat hierbij centraal.
      <br/><br/>
      In een tijd waarin alles gemakkelijk beschikbaar is, kies ik er met interieuradvies voor om te kijken naar wat
      hergebruikt kan worden. Een nieuw interieur betekent immers niet dat alles nieuw moet zijn. Vaak kunnen bestaande
      items, door ze anders te plaatsen of een likje verf te geven, al een hele nieuwe uitstraling krijgen. Deze
      benadering zorgt niet alleen voor een uniek en persoonlijk interieur, maar draagt ook bij aan een duurzamer
      gebruik van middelen.
    </ImageAndText>
    <TextCentered title="Interieuradvies aan huis in Apeldoorn" onClick="/" buttonText="Bekijk de mogelijkheden">
      Woon je in Apeldoorn en heb je behoefte aan interieuradvies aan huis? Ik kom graag bij je langs om al je woon- en
      interieurvragen te beantwoorden. Of je nu meer sfeer wilt creëren, je huidige interieur een frisse uitstraling
      wilt geven, je indeling wilt aanpassen of nieuwe meubelt wilt, ik sta voor je klaar.
      <br/><br/>
      Tijdens een advies aan huis kijken we samen naar de mogelijkheden en geef ik praktische tips die jou direct op weg
      helpen. Dit type advies is ideaal voor de doe-het-zelver die net dat ene duwtje in de juiste richting nodig heeft.
      Mijn doel is om jou te inspireren, zodat je met hernieuwd vertrouwen zelf aan de slag kunt gaan met je interieur.
      <br/><br/>
      <SubTitle>Interieuradvies tijdens (ver)bouwen in Apeldoorn</SubTitle>
      Ga je binnenkort (ver)bouwen in Apeldoorn? Dan is dit het perfecte moment om na te denken over je interieur. Ik
      bied <Link href="/" target="_blank">interieuradvies</Link> aan voor zowel kleine als grote bouw- en
      verbouwprojecten, vanaf één etage. Door al in een vroeg stadium betrokken te raken, kan ik meedenken over de
      indeling, materiaalkeuze, verlichting en natuurlijk de uiteindelijke inrichting.
      <br/><br/>
      Zo zorg ik ervoor dat alle keuzes tijdens het bouwproces naadloos aansluiten bij jouw interieurwensen. Samen
      brengen we in kaart wat je huidige leefsituatie mist en wat jouw grootste wensen zijn. Hierdoor creëren we een
      interieur dat perfect aansluit bij jouw manier van leven en persoonlijke stijl.
    </TextCentered>
    <ImageAndTextAlternative title="De toegevoegde waarde van een interieurstylist" onClick={onClick}
                             image="/img/landingpage/interieurstylist-apeldoorn.jpg">
      Als interieurstylist is het mijn passie om sfeer en eenheid te brengen in jouw interieur. Tijdens mijn studie op Academie Artemis (Bachelor of Arts tot allround creative concept developer in trend forecasting, styling en art direction) en jarenlange ervaring, ben ik gespecialiseerd in het gebruik van kleuren en materialen.
      <br/><br/>
      Met interieuradvies zorg ik ervoor dat jouw interieur niet alleen stijlvol is, maar ook functioneel en passend bij jouw levensstijl. Door middel van moodboards en shoppingpagina’s maak ik een concreet plan dat je helpt om je droominterieur te realiseren. Een moodboard helpt je om de juiste sfeer, kleuren en materialen te kiezen, terwijl de shoppingpagina praktische productaanbevelingen bevat die je eenvoudig kunt aanschaffen.
      <br/><br/>
      <SubTitle>Interieurontwerp op maat</SubTitle>
      Bij Livaun bied ik ook uitgebreide interieurontwerpen aan, ideaal voor wie op zoek is naar een complete make-over of bezig is met (ver)bouwplannen. Een interieurontwerp helpt je om een duidelijk beeld te krijgen van het totaalplaatje, vooral wanneer het gaat om grote veranderingen.
      <br/><br/>
      Denk hierbij aan 2D plattegronden voor de indeling en verlichting, evenals een doordachte keuze van materialen en kleuren. Samen maken we een plan dat perfect aansluit op jouw wensen, gezinssamenstelling en leefstijl.
      <br/><br/>
      <SubTitle>Neem contact op voor interieuradvies</SubTitle>
      Of je nu in een nieuw huis in Apeldoorn trekt of je huidige woning een update wilt geven, ik sta klaar om jou te helpen. Neem gerust contact met me op om de mogelijkheden voor interieuradvies te bespreken en jouw wensen in kaart te brengen. Samen creëren we een interieur dat niet alleen mooi is, maar ook écht als thuis voelt.
    </ImageAndTextAlternative>
  </StyledInteriorAdviceContent>
);

export default InteriorAdviceContent;
