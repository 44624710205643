import React from "react";
import styled, { css } from "styled-components";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import { Fade } from "react-reveal";
import Paragraph from "../../atoms/Paragraph";

const StyledInteriorStylist = styled.div`
  padding: 70px 0 20px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }
  
  @media only screen and (min-width: 768px) {
    padding: 0 100px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont", sans-serif;
  line-height: normal;
  font-size: 24px;
  font-weight: 700;
  color: #f0f0f0;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 100%;
`;

const Article = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 48%;
  background-image: url(${props => props.backgoundImage});
  background-color: ${props => props.backgroundColor};

  @media only screen and (min-width: 992px) {
    width: 50%;
  }

  ${props =>
    props.backgroundColor &&
    css`
      padding: 30px;
      
      @media only screen and (min-width: 992px) {
          padding: 70px;
      }
    `};
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    flex-direction: ${props => (props.reversed ? "row-reverse" : "row")};
    padding: 0 100px;
  }
`;

const StyledLink = styled.a`
    border: 0;
    outline: 0;
    width: auto;
    height: 60px;
    padding: 19px 20px;
    font-size: 16px;
    font-family: "MyanmarMN Webfont", sans-serif;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background-color: #9d735a;
    color: white;
    text-decoration: none;

    &:hover {
        background-color: #9d735a;
    }
`;

const SecondLink = styled(StyledLink)`
    background-color: #8d6751;

    &:hover {
        background-color: #8d6751;
    }
`;

const ThirdLink = styled(StyledLink)`
    background-color: #c2b8ab;
    
    &:hover {
        background-color: #c2b8ab;
    }
`;


const LinkWrapper = styled.div`
    display: flex;
    height: auto;
    margin-top: 20px;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 18px;
  color: #f0f0f0;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const OverviewContent = ({ designScrollRef }) => (
  <StyledInteriorStylist ref={designScrollRef}>
    <StyledContainer compact>
      <TextWrapper>
        <Item>
          <Title>Interieuradvies</Title>
        </Item>
        <Fade bottom distance="20px">
          <ArticleWrapper>
            <Article backgoundImage="/img/Livaun-apeldoorn.jpg" />
            <Article backgroundColor="#b79b83">
              <SubTitle>Apeldoorn</SubTitle>
              <StyledParagraph>
                Apeldoorn staat met haar bosrijke omgeving bekend als de
                hoofdstad van de Veluwe. Van oude wijken met karakteristieke
                panden tot nieuwbouwwijken met moderne huizen. Binnen Apeldoorn
                kom ik in veel verschillende woningen voor mijn interieuradvies,
                interieurstyling en interieurontwerp.
              </StyledParagraph>
              <LinkWrapper>
                <StyledLink href="/interieuradvies-apeldoorn">
                  Interieuradvies in Apeldoorn
                </StyledLink>
              </LinkWrapper>
            </Article>
          </ArticleWrapper>
          <ArticleWrapper reversed>
            <Article backgoundImage="/img/Livaun-harderwijk.jpg" />
            <Article backgroundColor="#b79b83">
              <SubTitle>Harderwijk</SubTitle>
              <StyledParagraph>
                Harderwijk is een Hanze- en vestingstad die ligt aan de rand van
                de Veluwe, aan het Wolderwijd. De stad telt 49.000 inwoners.
                Harderwijk is een snelgroeiende stad met mooie oude pandjes in
                de binnenstad en veelal nieuwbouw in de woonwijken. Livaun is
                gevestigd in California, een creatief bedrijfsverzamelgebouw in
                Harderwijk.
              </StyledParagraph>
              <LinkWrapper>
                <StyledLink href="/interieuradvies-harderwijk">
                  Interieuradvies in Harderwijk
                </StyledLink>
              </LinkWrapper>
            </Article>
          </ArticleWrapper>
          <ArticleWrapper>
            <Article backgoundImage="/img/Livaun-putten.jpg" />
            <Article backgroundColor="#b79b83">
              <SubTitle>Putten</SubTitle>
              <StyledParagraph>
                In Putten ben ik opgegroeid. Putten is een plaats op de grens
                van de Veluwe en de Gelderse Vallei en wordt omgeven door
                prachtige natuur en akkerlanden. Om deze reden is Putten in trek
                bij veel mensen uit het westen die het buitenleven opzoeken. Als
                je geïnteresseerd bent interieuradvies, interieurstyling en
                interieurontwerp voor je eigen woning in Putten? Ik help je erg
                graag!
              </StyledParagraph>
              <LinkWrapper>
                <StyledLink href="/interieuradvies-putten">
                  Interieuradvies in Putten
                </StyledLink>
              </LinkWrapper>
            </Article>
          </ArticleWrapper>
        </Fade>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorStylist>
);

export default OverviewContent;
