import React from "react";
import styled from "styled-components";
import Responsive from "react-responsive";
import ImageAndText from './components/ImageAndText';
import TextCentered from './components/TextCentered';
import Link from './components/Link';
import ImageAndTextAlternative from './components/ImageAndTextAlternative';
import SubTitle from './components/SubTitle';

const StyledInteriorAdviceContent = styled.div``;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorAdviceContentHarderwijk = ({ designScrollRef, onClick }) => (
  <StyledInteriorAdviceContent ref={designScrollRef}>
    <ImageAndText title="Interieuradvies Harderwijk" buttonText="Kan ik je helpen?" onClick={onClick} image="/img/landingpage/interieuradvies-harderwijk.jpg">
      Ben je woonachtig in Harderwijk en op zoek naar persoonlijk interieuradvies dat aansluit bij jouw persoonlijke smaak en wensen? Bij Livaun help ik je graag om jouw ideale woonomgeving te realiseren. Of je nu houdt van een landelijke, moderne, Scandinavische of vintage stijl, met veel kleur of juist een neutrale inrichting, ik zorg ervoor dat jouw interieur volledig wordt afgestemd op jouw unieke smaak en leefstijl.
      <br/><br/>
      <SubTitle>Duurzaam en persoonlijk interieuradvies</SubTitle>
      Bij Livaun draait het om meer dan alleen het creëren van een mooi interieur. Ik kijk verder dan de laatste trends en richt me op een tijdloze inrichting die perfect past bij jou en jouw manier van leven. Als interieurstylist houd ik, Marjella, me bezig met innovatieve ontwerpen en duurzame oplossingen. Bewust omgaan met materialen is voor mij een belangrijk aspect van interieurontwerp.
      <br/><br/>
      In een tijd waarin alles eenvoudig beschikbaar is, kies ik er met interieuradvies voor om te kijken naar wat hergebruikt kan worden en hoe bestaande items een nieuwe plek of functie kunnen krijgen. Een nieuw interieur betekent voor mij niet dat alles nieuw moet worden aangeschaft. Vaak kunnen kleine aanpassingen, zoals een andere plaatsing of een likje verf, al een groot verschil maken. Deze aanpak resulteert niet alleen in een unieke en persoonlijke inrichting, maar draagt ook bij aan een duurzamer gebruik van meubels en accessoires.
    </ImageAndText>
    <TextCentered title="Interieuradvies aan huis" onClick="/" buttonText="Bekijk de mogelijkheden">
      Wil je graag persoonlijk interieuradvies bij jou thuis in Harderwijk? Ik kom met plezier bij je langs om je te helpen met al je woonvragen en ideeën. Of je nu meer sfeer en gezelligheid wilt creëren, je huidige interieur een frisse update wilt geven of je indeling wilt veranderen, ik sta klaar om je te inspireren en te begeleiden.
      <br/><br/>
      Tijdens een interieuradvies aan huis bekijken we samen de mogelijkheden en geef ik je praktische tips die je direct kunt toepassen. Dit soort advies is ideaal voor de doe-het-zelver die net dat extra steuntje in de rug nodig heeft om zijn of haar interieur naar een hoger niveau te tillen. Mijn doel is om jou te inspireren en je te helpen het vertrouwen te krijgen om zelf aan de slag te gaan met je interieur.<br/><br/>
      <SubTitle>Interieuradvies voor (ver)bouwprojecten</SubTitle>
      Ga je binnenkort bouwen of verbouwen in Harderwijk? Dit is het perfecte moment om ook over je interieur na te denken. Ik bied interieuradvies aan voor zowel kleine renovaties als grootschalige (ver)bouwprojecten, beginnend vanaf één etage. Door al in een vroeg stadium bij het proces betrokken te zijn, kan ik meedenken over de indeling van de ruimte, de keuze van materialen, verlichting en de uiteindelijke inrichting.
      <br/><br/>
      Op deze manier zorg ik met <Link href="/" target="_blank">interieuradvies</Link> ervoor dat alle beslissingen tijdens het bouwproces nauwkeurig worden afgestemd op jouw wensen. Samen brengen we in kaart wat je huidige woning mist, wat je nodig hebt en wat jouw grootste wensen zijn. Dit leidt tot een interieur dat naadloos aansluit bij jouw manier van leven en persoonlijke stijl.
    </TextCentered>
    <ImageAndTextAlternative title="De voordelen van een interieurstylist" onClick={onClick}
                             image="/img/landingpage/interieurstylist-harderwijk.jpg">
      Als interieurstylist is het mijn passie om sfeer en harmonie te brengen in jouw woonruimte. Met mijn achtergrond
      aan Academie Artemis (Bachelor of Arts tot allround creative concept developer in trend forecasting, styling en
      art direction) en jarenlange ervaring in de interieurbranche, ben ik gespecialiseerd in het creëren van interieurs
      die zowel functioneel als esthetisch zijn.
      <br/><br/>
      Ik neem de tijd om jou te leren kennen en te begrijpen wat jouw persoonlijke stijl en voorkeuren zijn. Door middel
      van moodboards en shoppingpagina’s werk ik een concreet en visueel plan uit dat je helpt om jouw droominterieur in
      Harderwijk te realiseren. Het moodboard geeft richting aan de sfeer, kleuren en materialen, terwijl de
      shoppingpagina praktische productaanbevelingen bevat die je eenvoudig kunt aanschaffen.
      <br/><br/>
      <SubTitle>Interieurontwerp op maat</SubTitle>
      Naast persoonlijk interieuradvies bied ik ook uitgebreide interieurontwerpen op maat aan. Dit is ideaal voor
      wanneer je je hele woning een nieuwe uitstraling wilt geven of bezig bent met (ver)bouwplannen.
      <br/><br/>
      Een interieurontwerp geeft je een duidelijk beeld van het totaalplaatje, vooral als het om grote veranderingen
      gaat. Denk aan 2D plattegronden voor de indeling en verlichting, en zorgvuldig gekozen materialen en kleuren die
      de gewenste sfeer en stijl weerspiegelen.
      <br/><br/>
      Samen werken we een plan uit dat perfect aansluit op jouw wensen, gezinssamenstelling en levensstijl. Of je nu een
      nieuw huis in Harderwijk hebt gekocht of je huidige woning wilt vernieuwen, ik help je om van jouw huis een plek
      te maken die echt als thuis voelt.
      <br/><br/>
      <SubTitle>Samenwerken aan jouw droominterieur in Harderwijk</SubTitle>
      Ik sta klaar om jou te helpen bij het realiseren van jouw droominterieur in Harderwijk. Of je nu interieuradvies
      nodig hebt voor een specifieke ruimte, een complete verbouwing plant, of gewoon op zoek bent naar wat frisse
      inspiratie, ik ben er om je te begeleiden.
      <br/><br/>
      Neem contact op om de mogelijkheden voor interieuradvies te bespreken en jouw wensen en ideeën te verkennen.
    </ImageAndTextAlternative>
  </StyledInteriorAdviceContent>
);

export default InteriorAdviceContentHarderwijk;
