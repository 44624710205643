import React from "react";
import styled from "styled-components";
import Paragraph from '../../../atoms/Paragraph';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 70px 20px;

  @media only screen and (min-width: 992px) {
    padding: 100px;
  }
`;

const Title = styled.h1`
    position: relative;
    display: block;
    font-family: "MyanmarMN Webfont", sans-serif;
    font-weight: 700;
    color: rgb(205, 184, 168);
    margin-top: 0;
    margin-bottom: 20px;
    max-width: 416px;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 2.4rem;
    
  @media only screen and (min-width: 992px) {
      font-size: 48px;
      line-height: 3.5rem;
  }

  img {
    position: absolute;
    left: 160px;
    bottom: -15px;
    max-width: 120px;

    @media only screen and (min-width: 992px) {
      left: 210px;
      bottom: -12px;
      max-width: 130px;
    }

    @media only screen and (min-width: 1024px) {
      left: 200px;
      bottom: -14px;
    }
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #cdb8a8;
  font-size: 18px;
  line-height: normal;

  @media only screen and (min-width: 992px) {
    max-width: 450px;
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  max-width: 198px;
  height: 60px;
  padding: 0 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  background-image: url(${props => props.image});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  
  @media only screen and (min-width: 992px) {
      width: 50%;
  }
`;

const RightSide = styled.div`
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: 50%;
  }
`;

const InlineLink = styled.button`
  color: #cdb8a8;
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  font-family: "Crimson Text", serif;
  line-height: 1.4rem;
  font-size: 18px;
  padding: 0;
  cursor: pointer;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }

  &:hover {
    text-decoration: none;
  }
`;

const ImageAndText = ({ onClick, title, children, buttonText, image }) => (
  <>
    <Wrapper>
      <LeftSide image={image}></LeftSide>
      <RightSide>
        <TextWrapper>
          <Title>{title}</Title>
          <StyledParagraph>
            {children}
          </StyledParagraph>
          <StyledButton onClick={onClick}>{buttonText}</StyledButton>
        </TextWrapper>
      </RightSide>
    </Wrapper>
  </>
);

export default ImageAndText;
